import education3 from '../../images/education-3.svg';
import education1 from '../../images/education-1.svg';
import education2 from '../../images/education-2.svg';
import education4 from '../../images/character 6.svg';
import education5 from '../../images/character 2.svg';


export const homeObjOne = {
    id: 'about',
    topLine: '',
    headLine: 'The story so far',
    description: ' 3+ years of experience building and optimizing software applications with a focus on backend technologies, API development, database management and machine learning.',
    description2:'Founder of Metrikflow, a SaaS Solution that helps companies measure, track and reduce their carbon footprint.',
    description3:'I like people who are passionate about what they do and who are focused on solving problems rather than reacting to them.',
    x1: '',
    x2: '',
    x3: '',
    x4: '',
    buttonLabel: 'Let\'s work together',
    imgStart: false,
    img: education3,
    alt: 'teacher'
};

export const homeObjTwo = {
    id: 'motto',
    topLine: 'Credo',
    headLine: 'Gradatim Ferociter',
    description: '"Step by step, ferociously" by Jeff Bezos. This is my life motto. I believe in taking small steps towards a goal, but with a relentless and ferocious attitude. I believe in the power of grit and persistence.',
   
    buttonLabel: 'Let\'s work together',
    imgStart: true,
    img: education1,
    alt: 'teacher'
};

export const homeObjThree = {
    id: 'organizations',
    topLine: '',
    headLine: 'Organizations',
    description: '',
   
    buttonLabel: 'Let\'s work together',
    imgStart: false,
    img: education4,
    alt: 'teacher'
};

export const homeObjFour = {
    id: 'volunteer',
    topLine: '',
    headLine: 'Giving Back Along the Way',
    description: '',
   
    buttonLabel: 'Let\'s work together',
    imgStart: false,
    img: education2,
    alt: 'teacher'
};

export const homeObjFive = {
    id: 'education',
    topLine: '',
    headLine: 'Academic Milestones',
    description: '',
   
    buttonLabel: 'Let\'s work together',
    imgStart: false,
    img: education5,
    alt: 'teacher'
};
