import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
    background : ${({ scrollNav }) => (scrollNav ? '#fff' : 'transparent')};
    height : 80px;
    margin-top : -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: ${({ scrollNav }) => (scrollNav ? '0px 0px 50px 0px rgba(0, 0, 0, 0.1)' : 'none')};
    
    
    @media screen and (max-width:960px) {
        transistion : 0.8s all ease ;
    } 

`;

export const NavBarContainer = styled.div`
    height : 80px;
    /*margin-top : -80px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    padding : 0 10px;
    max-width : 1100px;
    z-index: 1;
`;
export const NavLogo = styled(LinkR)`
    justify-self : flex-start;
    
    cursor : pointer;
    font-size : 2rem;
    display: flex ;
    align-items: center;
    margin-left: -180px;
    // font-weight : 600;
    text-decoration : none;
    margin-top:0px;
    color: ${({ scrollNav }) => (scrollNav ? '#000' : '#fff')};
    outline: none;
    border:none;
    &:hover {
        text-decoration : none;
        color: #FFFFFF
    }

    @media screen and (max-width:568px) {
        padding-left: 150px;
        font-size : 1.5rem;
    }

    @media screen and (max-width:640px) {
        padding-left: 150px;
        font-size : 1.5rem;
    }

    @media screen and (max-width:768px) {
        padding-left: 150px;
        font-size : 1.5rem;
    }

    @media screen and (max-width:1500px) {
        margin-left: -140px;
    }
    

`;

export const MobileIcon = styled.div`
   /* display : none;*/

        display: block;
        position : absolute;
        top: -11px;
        right:0;
        transform : translate(-100%, 60%);
        font-size:1.8rem;
        cursor : pointer;
        /*color :#050A30;*/
        color: ${({ scrollNav }) => (scrollNav ? '#000' : '#fff')};
    
    @media screen and (max-width:1200px) {
        
    } 
`;

export const NavMenu = styled.ul`
    display: none ;
    /*display : flex;*/
    align-items :center;
    list-style : none;
    text-align :center;
    margin-top: 16px;
    margin-left: 220px;
    
    @media screen and (max-width:1200px) {
        display: none;
    } 
`;

export const NavItem = styled.li`
    height : 80px;

`;
export const NavLinks = styled(LinkS)`
    height : 100%;
    /*color: ${({ scrollNav }) => (scrollNav ? '#050A30' : '#fff')};*/
    color: #000;
    display:flex;
    align-items : center;
    text-decoration : none;
    cursor : pointer;
    padding : 0 1rem;
    margin-bottom: 0;
    font-weight : bold;


    &.active {
        border-bottom : 5px solid #e00e14;
    }

    &:hover{
        text-decoration : none;
        border-bottom : 5px solid #e00e14;
        color: #000;
    }
`;

export const NavBtn = styled.nav`
    display:  none;
    /*display : flex;
    align-items : center;*/

    
`;

export const NavBtnLink = styled(LinkR)`
display: none;
    border-radius : 50px;
    color : #fff;
    white-space: nowrap;
    padding: 10px 22px;
    background: #e00e14;
    font-size: 16px
    outline:none;
    border:none;
    cursor :pointer;
    transistion : all 0.2s ease-in-out;
    text-decoration : none;
    font-weight : bold;
    /*margin-right: 40px;*/
    border : 1px solid #e00e14;


    &:hover {
        transition : all 0.2s ease-in-out;
        color : #e00e14;
        background : #fff;
        text-decoration: none;
        border : 1px solid #e00e14;
    }

`;


