import styled from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';

export const HeroContainer = styled.div`
    background : #0c0c0c;
    display:flex;
    align-items : center;
    justify-content : center;
    padding : 0 30px;
    height: 1000px;
    position :relative;
    z-index :1;

       

`;

export const HeroBg = styled.div`
    position :absolute;
    top:0;
    right :0;
    left :0;
    bottom:0;
    width:100%;
    height:100%;
    opacity:0.3;
     

`;

export const VideoBg = styled.video`
    width:100%;
    height:100%;
    -o-object-fit: cover;
    object-fit:cover;
    background : #232a34;  
    
`;

export const HeroContent = styled.div`
    z-index : 3;
    max-width :1200px;
    position : absolute;
    padding :8px 24px;
    display : flex;
    flex-direction :column;
    align-items : center;
`;

export const HeroH1 = styled.h1`
   /*color: #252958;*/
   color: #fff;
   font-weight : bold;
   
   font-size: 100px;
   text-align : center;

   @media screen and (max-width:768px) {
       font-size : 50px;
   }
   @media screen and (max-width:480px) {
       font-size : 32px;
   }
`;

export const HeroP = styled.p`
   color: #fff;
   margin-top : 24px;
   font-size: 35px;
   text-align : center;
   max-width : 600px;
   
   

   @media screen and (max-width:768px) {
       font-size : 27px;
   }
   @media screen and (max-width:480px) {
       font-size : 18px;
   }
`;

export const HeroBtnWrapper = styled.div`
   margin-top : 32px;
   display: flex;
   flex-direction: column;
   align-items:center;
   font-weight: bold;

  
`;

export const ArrowForward = styled(MdArrowForward)`
   margin-left : 8px;
   font-size : 20px;

`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
   margin-left : 8px;
   font-size : 20px;

`;

