import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './pages';
import authService from './services/authService';

class App extends Component {


  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={props => {
            if (authService.getCurrentUser()) return <Redirect to="/home" />
            return <Home />
          }} />
          {/*
          <Route exact path="/signin" render={props => {
            if (authService.getCurrentUser()) return <Redirect to="/home" />
            return <SignInPage />
          }} />
          <Route exact path="/contact" render={props => {
            if (authService.getCurrentUser()) return <Redirect to="/home" />
            return <SignUpPage />
          }} />
          <Route path="/logout" component={Logout} />
          <Route exact path="/home" render={props => {
            if (!authService.getCurrentUser()) return <Redirect to="/signin" />
            return <UserDashboard />
          }} />
          */}
        </Switch>

      </BrowserRouter>
    );
  }
}

export default App;
