import axios from 'axios';
import { toast } from 'react-toastify';

axios.interceptors.response.use(null, error =>{
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

    if(!expectedError){
        console.log(error);
        toast.error("An unexpected error occurred !");
    }

    return Promise.reject(error);
});

function setJwt(jwt) {
    axios.defaults.headers.common["x-auth-token"] = jwt; // including the token in the header of all requests if it exists
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    get:axios.get,
    post:axios.post,
    put:axios.put,
    delete:axios.delete,
    setJwt
}
