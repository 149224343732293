import React, { useState } from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { AiFillMinusCircle } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';


const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 700;
  background: #fff;
  color:#000;
  margin-bottom: 30px;
  
  
`;

const Container = styled.div`
  position: relative;
  background: #fff;
`;

const Wrap = styled.div`
  background: #fff;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  margin-bottom: 5px;
  height: 50px;
  border-bottom: 1px solid #D3D3D3;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  
  

  h1 {
    padding: 2rem;
    font-size: 1.2rem;
  }

  span {
    margin-right: 1.5rem;
  }

   @media screen and (max-width:480px) {
       width : 330px;
       
   }

  
`;

const Dropdown = styled.div`
  background: #ffffff;
  color: #000;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 20px;
  

  p {
    font-size: 1rem;
    
  
  }

  li {
    margin-left: 0px;
    margin-top: 10px;
    list-style-type:none;
  }

  ul {
    margin-top: 10px;
  }

  
  a {
    
    color : #000;
    font-weight: bold;
    font-size: 20px;
    
    &:hover{
        
        text-decoration: none;
        color: #FFCC00;
    }
  
  }

  @media screen and (max-width:480px) {
       padding-left : 25px;
       height: 500px;
       max-width: 300px;

       p {
        font-size: 0.85rem;
       }

       a {
        font-size: 18px;
       }
       
   }


`;

const style = { color: "black", fontSize: "1.5em" }

const Accordion = ({accObj}) => {

const [clicked, setClicked] = useState(0);


  const toggle = index => {
    if (clicked === index) {
      //if clicked item is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };


  return (
    <>
    
    <IconContext.Provider value={{ color: '#000', size: '25px' }}>
    
      <AccordionSection>
      
        <Container>
          {accObj.map((item, index) => {
            return (
              <>
                <Wrap onClick={() => toggle(index)} key={index}>
                  <h1>{item.date}</h1>
                  <span>{clicked === index ? <AiFillMinusCircle style={style} /> : <BsFillPlusCircleFill style={style} />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown>
                    <p>
                    <h4>
                    {item.role}
                    </h4>
                    <a href={item.link} target='_blank' style={{textDecoration: "underline", color:"blue"}} rel="noreferrer">
                    {item.comp}
                      
                    </a>
                 
                    <br></br>
                    <ul>
                    
                    {item.missions ? item.missions.map((mission) => <li>{mission}</li>) : ''}
                      
                    </ul>
                    </p>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
    </>
  );
};

export default Accordion;
