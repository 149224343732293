export const accObjOne = [
  {
    date: 'Aug 2022 - Present ',
    role: 'Founder & CTO',
    comp: ' Metrikflow',
    link: 'https://metrikflow.com',
    missions: [
      'Co-founded a software company for carbon accounting',
      'Led a team of 10 and achieved 6% annual revenue growth, resulting in increased market share and profitability.',
    ]
  },  
  {
    date: 'Dec 2022 - Present ',
    role: 'Software Engineer',
    comp: ' Odoo',
    link: 'https://www.odoo.com',
    missions: [
      'Collaborated with stakeholders and business analysts to implement 53 new features, resulting in a 25% increase in user satisfaction and retention rates.',
      'Collaborated with a team of 8 to tailor software for a major client, resulting in a 15% reduction in customer support tickets and increased client success.',
      'Revolutionized the dashboard experience in Odoo by building an Excel-like solution that acts as a core data processing and calculation platform for business dashboards.',
      'Reduced processing times by 20% through optimization of calculation algorithms and query performance, resulting in improved system efficiency and faster response times.'
    ]
  },  
  {
    date: 'Jan 2022 - Jul 2022 ',
    role: 'Machine Learning Engineer',
    comp: ' Forenamics Gmbh',
    link: 'https://www.linkedin.com/company/forenamics/posts/?feedView=all',
    missions: [
      'Trained statistical models for time series forecasting relying on ML model ensembling/Deep Learning.',
      'Established a data pipeline and a microservices backend architecture using Python(Flask), Typescript, Firebase, BigQuery and AWS Forecast.',
      'Automated the deployment process with Gitlab CI/CD.',
      'Built a dashboard to visualize results with React JS.',
    ]
  },  
  {
    date: 'Aug 2021 - Oct 2021 ',
    role: 'Data Engineer Intern',
    comp: ' Wall Street Football',
    link: 'https://wallstreetfootball.io/wsf-academy-contributors/',
    missions: [
      'Improved work flow by establishing a data scraping automation pipeline. (Extract-Load-Transform)',
      'Explored, analyzed and visualized historical data of Football players’ performance.',
      'Trained and evaluated machine learning models : Gradient Boosted Trees, K Nearest Neighbours, Random Forest.',
    ]
  },
  {
    date: 'Jul 2021 - Sep 2021',
    role: 'Software Engineer Intern',
    comp: 'Talan',
    link: 'https://talan.com/en/',
    missions: [
      'Explored state of the art NLP models and techniques.',
      'Trained a Neural Machine Translation model for translating English sentences to French based on a Long Short Term Memory (LSTM) Neural Network Architecture with Python and Keras.',

    ]
  },
  {
    date: 'Apr 2021 - Jul 2021',
    role: 'Business Consultant',
    comp: 'Jungleworks',
    link: 'https://jungleworks.com/',
    missions: [
      'Pitched SaaS products to clients.',
      'Organized assigned business projects on behalf of clients according to client’s requirements.',
      'Met with assigned clients when needed and performed assessments of problematic situations.',
      'Provided guidance for occurring problems and issues.'
    ]
  },
  {
    date: 'Jul 2020 - Sep 2020',
    role: 'DevOps Engineer ntern',
    comp: 'Discovery Informatique',
    link: 'https://www.discoveryinformatique.com',
    missions: [
      'Performed a real time monitoring of servers and databases of QAD ERP software using Python, Bash Scripts and Zabbix.',
      'Established a CI/CD pipeline through automating deployment and builds with Jenkins and Git/Gitea.'    ]
  },
  {
    date: 'Jan 2020 - Jan 2021',
    role: 'Software Developer',
    comp: 'Freelance',
    link: '',
    missions: [
      'Developed REST APIs & establishing backend and frontend web architectures and integrations for clients according to their needs.',
      'Deployed web apps with Docker and docker-compose.',
      'Tech Stack : Html & CSS, PHP, Laravel, Javascript, MySQL, Node JS, React, MongoDB, Docker.'
    ]
  },
];

export const accObjTwo = [

  {
    date: 'Sep 2018 - Nov 2019',
    role: 'Social Entrepreneur & Speaker',
    comp: 'Enactus',
    link: 'https://enactus.org/',
    missions: [
      'Managed social entrepreneurship projects',
      'Co-founded a startup for ecological solutions',
      'Participated and pitched in entrepreneurship competitions (3rd place in "Finance in motion" competition)'
    ]
  },
  {
    date: 'Sep 2018 - Sep 2019',
    role: 'Sponsorship Manager',
    comp: 'i-Events',
    link: 'https://www.facebook.com/InsatEvents/',
    missions: [
      'Raised funds for our sports and cultural events.',
    ]
  },
  {
    date: 'Sep 2010 - Aug 2019',
    role: 'Backend Engineer',
    comp: 'Junior Entreprise INSAT',
    link: 'https://www.linkedin.com/company/junior-entreprise-insat/',
    missions: [
      'Developed the backend for RE/MAX Real Estate website.',
    ]
  }
]

export const accObjThree = [
  {
    date: '2017 - 2022',
    role: 'Engineer\'s Degree, Computer Science, Computer Networks Engineering, Specialized in Machine Learning.',
    link: 'https://www.linkedin.com/school/national-institute-of-applied-science-and-technology/',
  },
  {
    date: '2013 - 2017',
    role: 'High School Degree in Mathematics',
    link: 'https://www.linkedin.com/school/lyceepilotedelariana/'
  },

]
