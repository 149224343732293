import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { MobileIcon, Nav, NavBarContainer, NavItem, NavLinks, NavLogo, NavMenu, NavBtn, NavBtnLink } from './NavBarElements';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';


const NavBar = ({ handleOpen, user }) => {

    console.log(user);

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
        <React.Fragment>
            <Nav scrollNav={scrollNav} >
                <NavBarContainer >
                    <NavLogo to='/' onClick={toggleHome} scrollNav={scrollNav}>
                        {/*<img src={logo} width={300}></img>*/}
                        <span>MEHDI <span style={{ fontWeight: 900 }}>RACHICO.</span></span>
                    </NavLogo>
                    <MobileIcon onClick={handleOpen} scrollNav={scrollNav}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='about' smooth={true} duration={1000} spy={true} exact='true' offset={-80}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='programs' smooth={true} duration={1000} spy={true} exact='true' offset={-80} >Experience</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='prices' smooth={true} duration={1000} spy={true} exact='true' offset={-80} >Academic Milestones</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='services' smooth={true} duration={1000} spy={true} exact='true' offset={-80} >Certificates</NavLinks>
                        </NavItem>
                    </NavMenu>
                    {!user && (
                        <NavBtn>
                            <NavBtnLink to='/contact' smooth={true} duration={1000} spy={true} exact='true' offset={-80} >Contact me</NavBtnLink>
                        </NavBtn>
                    )}
                    {user && (
                        <NavBtn>
                            <NavBtnLink to='/logout' smooth={true} duration={1000} spy={true} exact='true' offset={-80} >{user.name}</NavBtnLink>
                        </NavBtn>
                    )}
                </NavBarContainer>
            </Nav>
        </React.Fragment>
    );
}

export default NavBar;
