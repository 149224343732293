import styled from 'styled-components';



export const Button = styled.button`
    border-radius : 50px;
    background : #FFFFFF;
    white-space : nowrap;
    padding : 12px 30px;
    color : #000;
    font-size: 20px;
    outline:none;
    border:1px solid #6A6B6B;
    cursor:pointer;
    display: flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    /*text-transform: uppercase;*/
    
    // box-shadow: -5px 5px #6A6B6B, -2px 2px #6A6B6B, -1px 1px #6A6B6B;
    border: 1px solid #6A6B6B;

    &:hover {
        transition: all 0.2s ease-in-out;
        background : #f0f0f0;
        color: #000;
        text-decoration: none;
        border : 1px solid #6A6B6B;
        outline: none;
    }

    &:focus {
        outline: none;
    }
    
`;

