import styled from 'styled-components';

export const ServicesContainer = styled.div`
    height: ${({ lowHeight }) => (lowHeight ? '1200px' : '2000px')};
    display:flex;
    justify-content:center;
    flex-direction: column;
    align-items : center;
    background: #000;

    @media screen and (max-width:1100px){
        height: ${({ lowHeight }) => (lowHeight ? '2000px' : '2500px')};
    }

    @media screen and (max-width:768px){
        height: ${({ lowHeight }) => (lowHeight ? '2500px' : '5200px')};
    }

    @media screen and (max-width:480px){
        height: ${({ lowHeight }) => (lowHeight ? '2500px' : '5200px')};
    }

    @media screen and (max-width:375){
        height: ${({ lowHeight }) => (lowHeight ? '2500px' : '5200px')};
    }
`;

export const ServicesWrapper = styled.div`
    max-width :1350px;
    margin :0 auto;
    display:grid;
    grid-template-columns: ${({ twoColumnLayout }) => (twoColumnLayout ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;


export const ServicesCard = styled.a`
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 480px;
    height: ${({ lowHeight }) => (lowHeight ? '300px' : '350px')};
    padding: 30px;
    box-shadow: 0 1px 3px  rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        transform:scale(1.02);
        transition:all 0.01s ease-in-out;
        cursor: pointer;
    } 
`;

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
    font-size : 5rem;
    color: #fff;
    margin-bottom:64px;
    font-weight:bold;
    

    @media screen and (max-width:768px) {
        font-size:3rem;
    }

    @media screen and (max-width:480px) {
        font-size:2rem;
    }
`;

export const ServicesH2 = styled.h2`
    font-size : 1.1rem;
    margin-bottom:10px;
    font-weight:bold;
    text-align:center;
`;

export const ServicesP = styled.h2`
    font-size : 1rem;
    text-align:center;
    color: #808080;
`;
