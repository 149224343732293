import React from 'react';
import { SideBarContainer, CloseIcon, Icon, SideBarWrapper, SideBarLink, SideBarMenu } from './SideBarElements';


const SideBar = ({ isOpen, handleOpen, openModal, showModal }) => {
    return (
        <SideBarContainer isOpen={isOpen} onClick={handleOpen}>
            <Icon onClick={handleOpen}>
                <CloseIcon></CloseIcon>
            </Icon>
            <SideBarWrapper>
                <SideBarMenu>
                    <SideBarLink to='about' onClick={handleOpen}>About</SideBarLink>
                    <SideBarLink to='experience' onClick={handleOpen}>Organizations</SideBarLink>
                    <SideBarLink to='projects' onClick={handleOpen}>Portfolio Highlights</SideBarLink>
                    <SideBarLink to='education' onClick={handleOpen}>Academic Milestones</SideBarLink>
                    <SideBarLink to='volunteer' onClick={handleOpen}>Volunteering</SideBarLink>
                    <SideBarLink to='certifications' onClick={handleOpen}>Recommendations</SideBarLink>
                </SideBarMenu>
            </SideBarWrapper>
        </SideBarContainer>
    );
}

export default SideBar;
