import React, { useState } from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroBtnWrapper, HeroP } from './HeroElements';
//import Video from 'assets/videos/tech.mp4';
import { Button } from '../ButtonElement';

const HeroSection = ({ openModal, showModal }) => {

    const [hover, setHover] = useState(false);

    const handleHover = () => {
        setHover(!hover);
    }


    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={'assets/videos/backvid.mp4'} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                {/* <HeroH1>Hi, I'm Mehdi.</HeroH1> */}
                <HeroP>I help people make better decisions using <s>coffee</s> data.</HeroP>
                <HeroBtnWrapper>
                    <Button onClick={openModal} onMouseEnter={handleHover} onMouseLeave={handleHover}>
                        Let's work together 
                        {/* {hover ? <ArrowForward /> : <ArrowRight />} */}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
}

export default HeroSection;
