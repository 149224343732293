import React, { Component } from 'react';
import SideBar from '../components/SideBar'
import NavBar from '../components/NavBar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, 
         homeObjThree, 
         homeObjTwo, 
         homeObjFour, 
         homeObjFive } from '../components/InfoSection/Data';
import Services from '../components/Services';
import Footer from '../components/Footer';
import auth from '../services/authService';
import { accObjOne, 
         accObjTwo,
         accObjThree} from '../components/Accordion/Data';
import {srv1, srv2, srv3} from '../components/Services/data';

class Home extends Component {

    constructor() {
        super();

        this.state = {
            isOpen: false,
            user: '',
            showModal: false,
        }
    }


    componentDidMount() {
        let user = auth.getCurrentUser();
        console.log(user);
        this.setState({ user });

    }



    handleOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    openModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    }



    render() {
        const { isOpen, user, showModal } = this.state;
        return (

            <React.Fragment>
                <SideBar isOpen={isOpen} handleOpen={this.handleOpen} showModal={showModal} openModal={this.openModal} />
                <NavBar handleOpen={this.handleOpen} user={user} />
                <HeroSection showModal={showModal} openModal={this.openModal} />
                <InfoSection {...homeObjOne} showModal={showModal} openModal={this.openModal} />
                <InfoSection {...homeObjTwo} bgDark={true} lightColor={true}  showModal={showModal} openModal={this.openModal} />
                <InfoSection {...homeObjThree} showModal={showModal} accordion ={true} openModal={this.openModal} accObj={accObjOne} />
                <Services data={srv3} lowHeight={true} twoColumnLayout={true}/>
                <InfoSection {...homeObjFive} showModal={showModal} accordion ={true} openModal={this.openModal} accObj={accObjThree} />
                <Services data={srv2} lowHeight={true} twoColumnLayout={false}/>
                
                <InfoSection {...homeObjFour} showModal={showModal} accordion ={true} openModal={this.openModal} imgStart={true} accObj={accObjTwo}/>
                <Services data={srv1} lowHeight={true} twoColumnLayout={true}/>
                <Footer showModal={showModal} openModal={this.openModal} />
            </React.Fragment>
        );
    }
}

export default Home;


