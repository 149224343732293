import http from './httpService';
import { apiUrl } from '../config.json';
import jwtDecode from 'jwt-decode';

const apiEndpoint = apiUrl + '/auth/login';
const tokenKey = "token";

http.setJwt(getJwt());

function login(email, password) {
    return http.post(apiEndpoint, {email, password});
}

function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey,jwt);
}

function logout() {
    localStorage.removeItem(tokenKey);
}

function getCurrentUser (){
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
        
    } catch (exception) {
        return null;
    }
}

function getJwt() {
    localStorage.getItem(tokenKey);    
}

const auth = {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getJwt
}

export default auth;
