import React from 'react';
import { InfoContainer, 
         InfoWrapper, 
         InfoRow, 
         Column1, 
         TextWrapper, 
         TopLine, 
         Heading, 
         Subtitle, 
         BtnWrap } from './InfoElements';
import { Button } from '../ButtonElement';
import ContactModal from '../ContactModal';
import Accordion from '../Accordion';


const InfoSection = ({ id, imgStart, topLine, headLine, description, description2, description3, accordion, img, alt, buttonLabel, bgDark, lightColor, x1, x2, x3, x4, openModal, showModal, accObj}) => {
    return (
        <React.Fragment>
            <ContactModal showModal={showModal} openModal={openModal} ></ContactModal>
            <InfoContainer id={id} bgDark={bgDark} >

                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                                    <TopLine lightColor={lightColor} >{topLine}</TopLine>
                                    <Heading lightColor={lightColor}>{headLine}</Heading>
                                    
                            <TextWrapper>
                                {accordion ? (
                                    <>
                                    <Accordion accObj={accObj}></Accordion>
                                    </>
                                    ) : (
                                    <>
                                   
                                    <Subtitle lightColor={lightColor}>{description}</Subtitle>
                                    <Subtitle lightColor={lightColor}>{description2}</Subtitle>
                                    <Subtitle lightColor={lightColor}>{description3}</Subtitle>
                                    </>
                                
                                )}
                                {id === '' && (
                                <BtnWrap>
                                    <Button onClick={openModal}>{buttonLabel}</Button>
                                </BtnWrap>
                                )}
                            </TextWrapper>
                        </Column1>
                        {/* <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2> */}
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>

        </React.Fragment>
    );
}

export default InfoSection;
