import React from 'react';

import { FooterContainer, FooterLink, FooterLinksContainer, FooterLinksWrapper, FooterWrap, } from './FooterElements';

const Footer = ({ openModal }) => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        

                            <FooterLink onClick={openModal}>Click here  to connect</FooterLink>

                  
                        




                    </FooterLinksWrapper>
                    
                </FooterLinksContainer>
                {/* <span style={{color:'white', fontSize:'0.75rem', textAlign:'center'}}>Crafted with ❤️ by <a href="https://linkedin.com/in/mehdi-rachico" target='_blank' rel='noreferrer'>Mehdi RACHICO</a></span> */}
            </FooterWrap>
        </FooterContainer>
    );
}

export default Footer;
