// export const srv1 = [
//     {
//         h1 : 'Certifications',
//         id: 'certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/86VYR8GVWNAU',
//         img : 'assets/images/uc-san-diego.png',
//         h2: 'Data Structures & Algorithms : Data Structures',
//         p: 'UC San Diego'
//     },
//     {
//         h1 : 'Certifications',
//         id: 'certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/BUE36P93LCH4',
//         img : 'assets/images/uc-san-diego.png',
//         h2: 'Data Structures and Algorithms : Algorithmic Toolbox',
//         p: 'UC San Diego'
//     },
//     {
//         h1 : 'Certifications',
//         id: 'certifications',
//         link : 'https://www.coursera.org/account/accomplishments/specialization/certificate/J6HJBF3F98AY',
//         img : 'assets/images/deep.jfif',
//         h2: 'Deep Learning Specialization',
//         p: 'DeepLearningAI'
//     },
//     {
//         h1 : 'Certifications',
//         id: 'certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/AJJP33TG26XR',
//         img : 'assets/images/deep.jfif',
//         h2: 'Sequence Models',
//         p: 'DeepLearningAI'
//     },
//     {
//         h1 : 'Certifications',
//         id: 'certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/YPP826FRBGXH',
//         img : 'assets/images/deep.jfif',
//         h2: 'Neural Networks and Deep Learning',
//         p: 'DeepLearningAI'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/SKGJD9X6NHMQ ',
//         img : 'assets/images/deep.jfif',
//         h2: 'Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization',
//         p: 'DeepLearningAI'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/EVSG5MWMQTWH',
//         img : 'assets/images/deep.jfif',
//         h2: 'Convolutional Neural Networks',
//         p: 'DeepLearningAI'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/7AJR27LNZ7J4',
//         img : 'assets/images/michigan.png',
//         h2: 'Applied Machine Learning with Python',
//         p: 'University of Michigan'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://rha.ole.redhat.com/rha/api/certificates/attendance/uuid/af254b9c-e962-4033-9470-6e86747a23fb',
//         img : 'assets/images/red_hat.png',
//         h2: 'Red Hat OpenShift I : Containers & Kubernetes (DO180)',
//         p: 'Red Hat'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/A5MBATJ8QWF7',
//         img : 'assets/images/michigan.png',
//         h2: 'Applied Text Mining in Python',
//         p: 'University of Michigan'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/PC8JV9N5GM5T',
//         img : 'assets/images/michigan.png',
//         h2: 'Introduction to Data Science in Python',
//         p: 'University of Michigan'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.credly.com/badges/cffea501-fb1b-48f3-bc8d-7464aef6fd03',
//         img : 'assets/images/certiprof.png',
//         h2: 'Scrum Foundations Professional Certificate (SFPC)',
//         p: 'Certiprof'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/JS23THRS5J5C',
//         img : 'assets/images/deep.jfif',
//         h2: 'Structuring Machine Learning Projects',
//         p: 'DeepLearningAI'
//     },
//     {
//         h1 : 'Certifications',
//         link : 'https://www.coursera.org/account/accomplishments/certificate/KMUSH7Y47UW9',
//         img : 'assets/images/michigan.png',
//         h2: 'Applied Plotting, Charting & Data Representation in Python',
//         p: 'University of Michigan'
//     },
                
        
// ]
export const srv1 = [
    {
        h1 : "Don't take my word for it",
        id: 'recommendations',
        h2: '❝Mehdi had a huge impact on Wall Street Football. He supported our Team of Data Scientists in several analytical tasks and at the same time he owned quite a few projects independently. Mehdi is very strong in term of technical skills, but at the same time he understands the business component very well and like to see the full picture. Mehdi has also very good communication ability, he is a smart and kind person and he is a great team-player. I am sure that he is going to have a very big impact in every project/company/team that he joins.❞',
        p: 'Giovanni Bertoli - Co-founder & CEO at Wall Street Football | Ex-Googler'
    },
    {
        h1 : "Don't take my word for it",
        id: 'recommendations',
        h2: '❝Mehdi has proved himself as an exceptional analyst and data scientist. He helped Wall Street Football solve a range of technical issues relating to the infrastructure and base of our models. He has applied techniques in machine learning, data science software development and analysis as a means to achieving the business tasks set upon him. Apart from that it was really easy and straightforward to communicate with Mehdi as he was always showing a positive and open attitude during difficult tasks, involving technologies such as R, Python, MongoDB and Git. I would recommend Mehdi as a valuable member of any analytics team as he is able to effectively leverage his technical skills and communication style to get the job done.❞',
        p: 'Yevgen Levin - Head of Data Engineering at WSF Oddss'
    },                 
    {
        h1 : "Don't take my word for it",
        id: 'recommendations',
        h2: '❝I have been teaching Mehdi this year advanced techniques of deep learning techniques for images and graphs and he has shown assiduity, discipline, good learning skills and big interest in deep learing study field.❞',
        p: 'Racha Friji - AI/ Quantum AI Researcher | PhD in AI |Techwomen Fellow 2022'
    },                 
    {
        h1 : "Don't take my word for it",
        id: 'recommendations',
        h2: '❝Mehdi is a great guy that I loved working with!❞',
        p: 'Milan Hofmann - Founder & CTO @ Stealth | Fellow bei Sigma Squared Society | Advisor-Tech @ BlueBurn'
    },                 
]



export const srv2 = [

    {
        h1 : 'Portfolio Highlights',
        id: 'projects',
        link : '',
        h2: 'Trained and deployed a deep learning model for detecting mental illness from user content on the social network Reddit.',
        p: 'Sep - Oct 2021'
    },
    {
        h1 : 'Portfolio Highlights',
        id: 'projects',
        link : '',
        h2: 'Built an Event Management Platform with online ticketing and online payments.',
        p: 'Sep - Dec 2021'
    },
    {
        h1 : 'Portfolio Highlights',
        id: 'projects',
        link : '',
        h2: 'Intelligent SDN traffic classification using deep learning: Trained and deployed a deep learning model to handle SDN network traffic to differentiate between normal and malicious traffic.',
        p: 'Mar - May 2021'
    }, 
    {
        h1 : 'Portfolio Highlights',
        link : '',
        h2: 'Built an app based on geolocation (using Google Maps API) that offers rewards (e.g, coupons) for people who perform humanitarian or eco-friendly actions like donation of blood, donation of clothing, collection of plastic bottles.',
        p: 'Mar - May 2020'
    },    
]
export const srv3 = [
    {
        h1 : 'Featured',
        id: 'featured',
        link : '',
        isSoon: true,
        h2: 'My experience with Metrikfow in the EEX journey incubator in Finland',
        p: 'September 2024'
    },
    {
        h1 : 'Featured',
        id: 'featured',
        youtubelink : 'rh2wmR7eQ8s',
        h2: 'My intervention in the Odoo Unplugged Podcast about why Odoo does not follow the trends of the software industry',
        p: "It's obvious yet easy to forget that simplicity can often lead to the best outcome.",
    },
    // {
    //     h1 : 'Featured',
    //     id: 'featured',
    //     link : '',
    //     // img : 'assets/images/tensorflow.png',
    //     h2: 'Winner logo at the 2021 edition of the IP Logo Competition',
    //     p: 'Dec 2021'
    // },
    // {
    //     h1 : 'Featured',
    //     id: 'featured',
    //     link : '',
    //     // img : 'assets/images/tensorflow.png',
    //     h2: 'The startup I worked on, with the Enactus team, that won the 2019 edition of the Finance in motion competition',
    //     p: 'Dec 2020'
    // },
]

// const srv4 = [
//     {
//         h1 : 'Skills',
//         id: 'skills',
//         link : '',
//         // img : 'assets/images/nodejs.png',
//         h2: 'Programming Languages : Python, JavaScript, Java, C, C++, PHP',
//         p: ''
//     },
//     {
//         h1 : 'Skills',
//         id: 'skills',
//         link : '',
//         // img : 'assets/images/tensorflow.png',
//         h2: 'Frameworks : React JS, Node JS, Laravel',
//         p: ''
//     },
//     {
//         h1 : 'Skills',
//         id: 'skills',
//         link : '',
//         // img : 'assets/images/tensorflow.png',
//         h2: 'Databases : MySQL, MongoDB',
//         p: ''
//     }, 
//     {
//         h1 : 'Skills',
//         link : '',
//         // img : 'assets/images/react.png',
//         h2: 'Tools : Git, Docker, Kubernetes, Jenkins, Ansible, Jira, Confluence',
//         p: ''
//     },    
// ]


