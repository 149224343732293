import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { FaTimes } from 'react-icons/fa';


export const SideBarContainer = styled.aside`
    position : fixed;
    z-index : 999;
    width:100%;
    height : 100%;
    background : #000;
    display :grid;
    align-items : center;
    left:0;
    transition : 0.3s ease-in-out;
    opacity : ${({ isOpen }) => (isOpen ? '92%' : '0')};
    top : ${({ isOpen }) => (isOpen ? '0' : '-100%')};
   
    
`;

export const CloseIcon = styled(FaTimes)`
    color:#fff;
`;

export const Icon = styled.div`
    position :absolute;
    top :1.2rem;
    right : 1.5rem;
    background : transparent;
    font-size : 2rem;
    cursor : pointer;
    outline:none;
`;

export const SideBarWrapper = styled.div`
    color : #fff;
`;

export const SideBarMenu = styled.ul`
    display : grid;
    grid-template-columns : 1fr;
    grid-template-rows :repeat(6,80px);
    text-align : center;

    @media screen and (max-width:480px){
        grid-template-rows :repeat(6,60px);
    }
`;

export const SideBarLink = styled(LinkS)`
    display : flex;
    align-items : center;
    justify-content: center;
    font-size : 1.5rem;
    text-decoration : none;
    list-style : none;
    transition : 0.2s ease-in-out;
    color : #fff;
    cursor : pointer;
    font-weight:bold;

    &:hover {
        color : #FFFFFF;
        transition : 0.2s ease-in-out;
        text-decoration : none;
    }
`;


export const SideBtnWrap = styled.div`
    display : flex;
    justify-content : center;
    
`;

export const SideBarRoute = styled(LinkR)`
    border-radius : 50px;
    background : #FFFFFF;
    white-space : nowrap;
    padding : 16px 64px;
    color : #000;
    font-size: 1.5rem;
    outline: none; 
    border : none;
    cursor : pointer;
    transition : all 0.2s ease-in-out;
    text-decoration : none;
    font-weight:bold;
     

    &:hover {
        transition : all 0.2s ease-in-out;
        color : #000;
        background : #0f0f0;
        text-decoration : none;
    }
`;


