import React from 'react';
import { ServicesCard, 
         ServicesContainer, 
         ServicesH1, 
         ServicesH2, 
         ServicesIcon, 
         ServicesP, 
         ServicesWrapper } from './ServicesElements';


const Services = ({data, lowHeight, twoColumnLayout}) => {
    return (
        <ServicesContainer id={data[0].id} lowHeight={lowHeight}>
            <ServicesH1>{data[0].h1}</ServicesH1>
            <ServicesWrapper twoColumnLayout={twoColumnLayout}>
            {data.map((item, index) => {
            return (
                <ServicesCard href={item.link} target='_blanck' lowHeight={lowHeight}>
                    {item.img && (<ServicesIcon src={item.img} />)}
                    <ServicesH2>{item.h2}</ServicesH2>
                    <ServicesP>{item.p}</ServicesP>
                    {item.youtubelink && (
                        <iframe
                            width="300"
                            height="400"
                            src={`https://www.youtube.com/embed/${item.youtubelink}?start=1072`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube video"
                        />
                    )}
                    {item.isSoon && (
                        <h5>Coming soon...</h5>
                    )}
                </ServicesCard>
              
            );
          })}
            

                

            
            </ServicesWrapper>
        </ServicesContainer>
    );
}

export default Services;
