import React from 'react';
import { Background, ModalWrapper, FormH1, FormH2, CloseModalButton, FormItem } from './ContactModalElements';
import * as Icons from "@tabler/icons";


export default function ContactModal({ showModal, openModal }) {

    return (
        <>
            {showModal ? (
                <Background>
                    <ModalWrapper showModal={showModal}>
                        <CloseModalButton onClick={openModal}></CloseModalButton>
                        <FormH1>Shall we get in touch?</FormH1>
                        {/* <img alt="mehdi_rachico" src={'assets/images/Mehdi_Rachico.png'} style={{ width: '40%', display: 'block', marginLeft: "auto", marginRight: "auto", borderRadius : "10px", marginTop :"20px", marginBottom: "20px"}}></img> */}
                        {/* <hr></hr> */}
                        <FormItem>

                            <FormH2>mehdirachico@yahoo.com</FormH2>
                            <Icons.IconBrandYahoo 
                                style={{display: 'block', marginLeft: "auto", marginRight: "auto" }}
                                
                                />
                        </FormItem>
                        

                        <FormItem href="https://www.linkedin.com/in/mehdi-rachico/" target='_blank'>
                            <FormH2>linkedin.com/in/mehdi-rachico</FormH2>
                            <Icons.IconBrandLinkedin
                                style={{display: 'block', marginLeft: "auto", marginRight: "auto" }}

                            />
                            </FormItem>
                        <FormItem href="https://x.com/mehdirachico" target='_blank'>
                            <FormH2>x.com/mehdirachico</FormH2>
                            <Icons.IconBrandTwitter 
                                style={{display: 'block', marginLeft: "auto", marginRight: "auto" }}

                            />
                        </FormItem>                       

                    </ModalWrapper>
                </Background>
            ) : null}
        </>
    );

}

